.root {
  position: relative;
}

.zoomBtn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.modal :global(.modal-header) {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1060;
}

.modal :global(.modal-body) {
  max-height: 90vh;
}
