.topicListItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.topicListItem i {
  display: none;
}

.topicListItem .topicsListItemBtn {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
  padding: 16px 30px 16px 16px;
  border: none;
  text-align: left;
  white-space: nowrap;
}

.topicListItem .topicsListItemBtn:hover,
.topicListItem .topicsListItemBtn:focus {
  text-decoration: none;
  color: var(--theme-primary);
}
