.btnGroup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.btnGroup :global(.btn):first-child,
.btnGroup :global(.btn):nth-child(2) {
  margin-right: 20px;
}

@media (max-width: 1199px) {
  .btnGroup {
    justify-content: center;
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .btnGroup {
    flex-wrap: wrap;
  }

  .btnGroup > :global(.btn) {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .btnGroup > :global(.btn):first-child,
  .btnGroup :global(.btn):nth-child(2) {
    margin-right: 0;
  }
}
