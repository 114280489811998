.aboutPage {
  padding: 60px 0 30px;
}

.aboutBtn {
  margin: 12px 0 28px;
}

.aboutPageSubtitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
  margin: 20px 0 28px;
}

.aboutPageTextList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.aboutPageItem {
  max-width: 33.3%;
  width: 100%;
}

.aboutPageItem img {
  max-width: 100%;
}

.aboutPageItem:not(:last-child) {
  margin-right: 26px;
}

.aboutPageText {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.videoTitle {
  text-align: center;
  text-transform: initial;
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .aboutPage {
    padding: 0;
  }

  .aboutPageTextList {
    flex-flow: row wrap;
  }

  .aboutPageItem {
    max-width: none;
  }

  .aboutPageItem:not(:last-child) {
    margin-right: 0;
  }

  .aboutPageItem:not(:first-child) {
    margin-top: 20px;
  }

  .aboutBtn {
    max-width: 320px;
    width: 100%;
    margin: 12px auto 28px;
    display: block;
  }
}
