:global(.notLoggedPageText) .theoryPageText,
:global(.loggedPageText) .theoryPageText {
  margin-bottom: 112px;
  max-width: 310px;
  width: 100%;
}

.theoryPageText :global h1 {
  margin-bottom: 17px;
}

.rulesList {
  display: flex;
  margin: 20px 0 40px;
}

.rulesListItem {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.rulesListItem:not(:last-child) {
  margin-right: 26px;
}

:global .rulesListItemBg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: var(--theme-primary-80);
  transition: all ease-in 0.3s;
}

.rulesListItemBg > i {
  font-size: 48px;
}

.rulesListItemText {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.rulesListItemBadge {
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  text-transform: uppercase;
  color: var(--whiteColor);
  background-color: var(--badge-color);
  text-align: center;
  padding: 8px;
  border-radius: 21px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.04px;
  width: 55px;
}

.linkBtn {
  display: flex;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: var(--theme-primary);
}

.linkBtn > .icon-arrow {
  color: currentColor;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 2px;
}

@media (max-width: 1199px) {
  .rulesList {
    justify-content: center;
  }

  .rulesListItemBg {
    background-color: var(--additional-light-blue);
  }

  .rulesListItemBadge {
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .rulesListItemBg {
    width: 60px;
    height: 60px;
  }

  .rulesListItemBg > i {
    font-size: 36px;
  }

  :global .theoryBtn {
    max-width: 350px;
    width: 100%;
  }

  :global(.notLoggedPageText) .theoryPageText {
    margin-bottom: 302px;
  }

  :global(.loggedPageText).theoryPageText {
    margin-bottom: 52px;
  }
}
