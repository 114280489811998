footer {
  padding: 40px 0 42px;
  border-top: 1px solid var(--neutral-gray);
  margin-top: 57px;
}

.socialLinkList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-top: 45px;
}

.socialLinkList > li:not(:last-child) {
  margin-right: 12px;
}

.socialLinkItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease-in-out 0.4s;
}

.socialLinkItem:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.brandLinkList {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding-top: 12px;
}

.brandLinkList > li {
  background-color: #f5f5f5;
  max-width: calc(50% - 8px);
  height: 36px;
  width: 100%;
  border-radius: 2px;
  margin-top: 12px;
}

.brandLinkList > li:nth-child(event) {
  margin-right: 12px;
}

.brandLinkItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footerTextWrapper {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  height: 100%;
}

.footerText {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.footerTextItem {
  white-space: nowrap;
}

.footerTextItem:not(:first-child):before {
  content: '·';
  margin: 0 5px;
}

.footerTextWrapper {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  height: 100%;
}

.footerCopyright,
.footerCopyrightLink,
.footerCopyrightMobile {
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 18px;
  color: rgba(75, 101, 132, 0.7);
}

.footerCopyright {
  margin-top: 16px;
}

.footerCopyrightLink {
  display: inline-block;
  padding: 0 2px;
}

.footerCopyrightLink:hover {
  text-decoration: none;
  color: rgba(75, 101, 132, 0.7);
}

@media (max-width: 1200px) {
  .socialLinkItem {
    width: 30px;
    height: 30px;
  }

  .socialLinkItem > i {
    font-size: 20px;
  }

  .footerTextItem:last-child {
    display: block;
  }

  .footerTextItem:last-child:before {
    content: none;
  }
}

@media (max-width: 991px) {
  footer {
    padding: 24px 0 30px;
    margin-top: 20px;
  }

  footer .socialLinkList,
  .footerCopyright {
    display: none;
  }

  .footerText {
    font-size: 14px;
    line-height: 22px;
  }

  .footerTextItem:last-child:first-letter {
    display: none;
  }

  .footerCopyrightMobile {
    margin-top: 36px;
  }

  .footerLogo {
    display: block;
    max-width: 117px;
    margin-bottom: 26px;
  }
}

@media (min-width: 992px) {
  .footerCopyrightMobile {
    display: none;
  }

  .footerCopyright {
    display: block;
  }
}
