.rulesGroupContent {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.imgContainer {
  max-width: calc(50% - 10px);
}

.imgContainer :global img {
  height: 100%;
  width: auto;
}

.questionsByTopic {
  padding-top: 80px;
}

.questionsByTopicTitle {
  color: var(--neutral-dark-blue);
}

.questionsByTopicList {
  column-count: 3;
  column-width: 390px;
  column-gap: 20px;
  margin: 54px 0 80px;
}

.questionsByTopicListItem {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.questionsLink {
  display: inline-block;
  margin: 16px 0;
  color: var(--neutral-dark-blue);
}

.questionsByTopicList > p {
  color: var(--neutral-gray-blue);
}

@media (max-width: 1199px) {
  .CockpitNotLoggedPage .imgContainer {
    display: none;
  }

  .btnList {
    justify-content: center;
    margin-bottom: 20px;
  }

  .btnList .btn {
    margin: 10px auto 0;
  }
}

@media (max-width: 991px) {
  .questionsByTopicList {
    column-width: 320px;
  }
}
