.cardCategoriesContainer {
  padding: 72px 0 88px;
  background-color: var(--additional-gray-blue);
}

.cardCategoriesItem {
  position: relative;
  background-color: var(--neutral-light-gray);
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 220px;
  overflow: hidden;
  border: none;
}

.cardCategoriesItem:first-child {
  background: var(--neutral-light-gray) url('../../../src/img/il-1.svg') no-repeat 100% 60%;
}

.cardCategoriesItem:nth-child(2) {
  background: var(--neutral-light-gray) url('../../../src/img/il-2.svg') no-repeat 90%;
}

.cardCategoriesItem :global(.card-body) {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 32px 36px 0;
  z-index: 2;
  width: 100%;
}

.cardCategoriesItem :global(.card-title) {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 16px;
}

.cardCategoriesItem :global(.card-text) {
  margin-bottom: 32px;
  color: var(--neutral-gray-blue);
  max-width: 300px;
}

.cardCategoriesItem :global(.card-footer) {
  position: relative;
  padding: 0 36px 28px;
  background-color: transparent;
  border-top: none;
  z-index: 2;
}

.cardCategoriesItem :global(.linkBtn) {
  display: inline-flex;
}

.cardCategoriesItem img {
  position: absolute;
  right: 5px;
  top: 15px;
  height: 240px;
  width: auto;
  z-index: 1;
}

.cardCategoriesList {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.cardCategoriesItem {
  max-width: calc(33.3% - 12px);
  width: 100%;
  margin-bottom: 20px;
}

.cardCategoriesItem:nth-child(1),
.cardCategoriesItem:nth-child(2) {
  max-width: calc(50% - 12px);
  width: 100%;
}

@media (max-width: 1199px) {
  .cardCategoriesItem :global(.card-title) {
    font-size: 30px;
    line-height: 39px;
  }
}

@media (max-width: 991px) {
  .cardCategoriesItem :global(.card-title) {
    font-size: 24px;
    line-height: 39px;
  }

  .cardCategoriesContainer {
    display: none;
  }

  .cardCategoriesItem,
  .cardCategoriesItem:nth-child(1),
  .cardCategoriesItem:nth-child(2) {
    max-width: none;
    margin-bottom: 12px;
  }

  .cardCategoriesContainer {
    padding: 30px 0 28px;
  }
}
