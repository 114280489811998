.sectionPromo {
  margin-bottom: 80px;
}

.sectionPromo :global(.pageTitle) {
  margin-bottom: 32px;
}

.sectionCard {
  padding: 70px 0 100px;
}

.rulesListMobile {
  display: none;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 50%;
}

.sectionAdsMobile {
  display: none;
}

@media (max-width: 991px) {
  .sectionCard {
    background-color: var(--neutral-light-gray);
    padding: 24px 0 36px;
  }

  .sectionPromo {
    margin-bottom: 20px;
  }

  .sectionAdsMobile {
    display: block;
    padding: 0 0 30px;
  }

  .mobileCarousel {
    display: block;
    background-color: var(--neutral-light-gray);
    padding: 40px 0;
  }
}
