.vipCard {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
  background: url('/static/images/vip-card.svg') no-repeat left center, var(--whiteColor);
  background-size: auto 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  padding: 26px;
}

.cardLogo {
  width: 90px;
  margin-bottom: 35px;
}

.cardTitle {
  font-family: 'Hind', sans-serif;
  font-size: 32px;
  line-height: 60px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--theme-primary);
  margin-bottom: 42px;
}

.ruleList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.ruleListItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: var(--theme-primary-80);
}

.ruleListItem:not(:first-child) {
  margin-left: 12px;
}

.ruleListItem > i {
  color: currentColor;
}

@media (max-width: 1199px) {
  .vipCard {
    padding: 17px;
  }

  .cardTitle {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 25px;
  }

  .cardLogo {
    width: 75px;
    margin-bottom: 42px;
  }

  .ruleListItem {
    width: 36px;
    height: 36px;
  }

  .ruleListItem > i {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .vipCard {
    padding: 14px;
    max-width: 420px;
    margin: 0 auto;
  }

  .cardLogo {
    width: 68px;
    margin-bottom: 25px;
  }

  .ruleListItem:not(:first-child) {
    margin-left: 10px;
  }

  .ruleListItem {
    width: 32px;
    height: 32px;
  }

  .ruleListItem > i {
    font-size: 24px;
  }
}
