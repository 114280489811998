.resultPageItem :global .jumbotron {
  max-width: 440px;
  margin-bottom: 36px;
}

.resultList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
  padding-right: 20px;
}

.resultLabel {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--neutral-gray-blue-50);
}

.resultValue {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.resultShareTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

@media (max-width: 1199px) {
  .resultPageItem {
    max-width: none;
    width: 100%;
  }
}
