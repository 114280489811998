.notificationContainer {
  max-width: 1440px;
  position: fixed;
  z-index: 11;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.notificationContainer button {
  margin-left: 15px;
}

@media (max-width: 450px) {
  .notificationContainer {
    font-size: 13px;
  }
}
