.resultPage {
  padding: 88px 0 140px;
}

.resultPageContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-right: 64px;
}

@media (max-width: 1199px) {
  .resultPage {
    padding: 24px 0 0;
  }

  .resultPageContent {
    flex-flow: row wrap;
    padding-right: 0;
  }

  .resultPageContentItem {
    width: 100%;
  }
}
