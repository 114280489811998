.registration {
  padding: 40px 0;
}

.registration :global(.linkBtn) {
  margin-bottom: 20px;
  margin-left: 20px;
}

.registration :global(.linkBtn .icon-arrow) {
  transform: rotate(180deg);
  margin-right: 5px;
}

.registration :global(.btn-secondary) {
  display: block;
  margin-left: auto;
  margin-top: 20px;
}

.formRegistration {
  margin-top: 24px;
}

.formTitle {
  font-family: 'Ubuntu', sans-serif;
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
  color: var(--neutral-dark-blue);
  margin-left: 20px;
}

.planInfo {
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  background-color: var(--neutral-light-gray);
  padding: 24px 32px;
}

.planInfoBox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.planInfoBox > .planInfoItem {
  max-width: calc(50% - 5px);
  width: 100%;
  margin-bottom: 8px;
}

.itemLabel {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.itemValue {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.registration :global(.form-group) {
  margin-bottom: 22px;
}

.registration :global .form-control {
  height: 44px;
  border: 1px solid var(--neutral-gray);
}

.paymentList :global(.list-group-item:not(:last-child)) {
  margin-bottom: 8px;
}

.paymentList :global(.list-group-item) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--neutral-light-gray);
  border: 1px solid var(--additional-gray-blue);
  border-radius: 10px;
  font-family: 'Hind', sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
  cursor: pointer;
  transition: all ease 0.3s;
  padding: 12px 13px;
}

.paymentList :global(.list-group-item:hover),
.paymentList :global(.list-group-item.active) {
  background-color: var(--whiteColor);
}

.paymentList :global(.p3) {
  color: var(--neutral-gray-blue);
}

:global(.custom-control-label::before) {
  position: absolute;
  top: 0;
  left: -24px;
  display: block;
  width: 24px;
  height: 24px;
  content: '';
  background-color: var(--whiteColor);
  border: 1px solid var(--neutral-gray);
  transition: all ease 0.3s;
}

:global .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

:global(.custom-control-input) {
  position: absolute;
  opacity: 0;
  z-index: 2;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
}

:global .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--neutral-dark-blue-70);
  background-color: var(--whiteColor);
}

:global .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  content: '\e902';
  color: var(--theme-primary);
  font-family: 'icomoon';
  width: 24px;
  height: 24px;
  top: 0;
  left: -24px;
  font-size: 24px;
}

:global .custom-control-input.is-valid:checked ~ .custom-control-label::before,
:global .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: var(--whiteColor);
}

:global .custom-control-input.is-valid:checked ~ .custom-control-label::after,
:global .was-validated .custom-control-input:valid:checked ~ .custom-control-label::after {
  color: var(--functional-success);
}

:global .custom-control-input.is-valid ~ .custom-control-label,
:global .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: var(--neutral-gray-blue);
}

:global .custom-checkbox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

:global .custom-control-label {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  padding-left: 20px;
}

.planInfoTopOffset {
  margin-top: 20px;
}

.planInfoTopOffsetMobile {
  display: none;
}

@media (max-width: 991px) {
  .planInfo {
    margin-bottom: 20px;
  }

  .registration :global(.linkBtn),
  .formTitle {
    margin-left: 0;
  }

  .formRegistration .planInfo {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .formRegistration .planInfo .itemLabel {
    display: none;
  }

  .formRegistration .planInfo.planInfoTopOffsetMobile .itemLabel {
    display: block;
  }

  .formRegistration .planInfo .itemValue,
  .formRegistration .planInfoBox .planInfoItem {
    margin-bottom: 0;
  }

  .formRegistration .planInfoBox {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 50%;
  }

  .formRegistration .planInfo > .planInfoItem > .itemValue,
  .formRegistration .planInfoBox .planInfoItem .itemValue {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  .formRegistration .planInfo > .planInfoItem > .itemValue,
  .formRegistration .planInfoBox .planInfoItem:last-child .itemValue {
    text-transform: uppercase;
    color: var(--neutral-dark-blue);
  }

  .formRegistration .planInfo > .planInfoItem > .itemValue {
    margin-right: 70px;
  }

  .formRegistration .planInfoBox .planInfoItem:first-child .itemValue {
    color: var(--neutral-dark-blue-50);
  }

  .planInfoTopOffsetMobile {
    display: block;
  }

  .planInfo.planInfoTopOffset {
    display: none;
  }
}

@media (max-width: 767px) {
  .planInfo {
    max-width: none;
  }

  .registration :global(.btn-secondary) {
    margin-left: 0;
    width: 100%;
  }
}
