.resultShareList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

.resultShareItem {
  width: 32px;
  height: 32px;
}

.resultShareItem:not(:last-child) {
  margin-right: 13px;
}
